.user {
  &__panel {
    margin-bottom: 0.5rem;
  }
}

.user__row {
  display: flex;
  align-items: center;
  gap: 4px;
}