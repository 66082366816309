.tasks {
  &__table-container {
    margin: auto;
    overflow-x: auto;
  }

  &__table {
    td {
      padding: 0.5rem;
    }
  }

  &__buttons {
    &>*:not(:first-child) {
      margin-left: 8px;
    }
  }
}
