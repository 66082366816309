.user-list {

  &__head {
    th {
      padding: 0.5rem 1rem;
      text-align: left;
      font-size: 1.1rem;
    }
  }

  &__table-container {
    margin: auto;
    overflow-x: auto;
  }
  &__table {
    td { 
      padding: 0.5rem 1rem;
    }
  }

  &__count {
    color: rgba(0, 0, 0, 0.5);
    padding: 0.5rem;
  }

  &__add-user {
    position: absolute;
    right: 1rem;
  }
}